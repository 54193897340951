import { observable, computed, makeObservable, override, action } from 'mobx';
import ModalCubit from './ModalCubit';
import { validateEmail } from '../Viewer/AnnotationLayer/Utils';
import { api } from '../Api/ProofXApi';
import { useProofXStore } from '../Store/ProofXStore';

export default class AddressBookRecordModalCubit extends ModalCubit {
    _name = '';
    _email = '';
    _isSubmitting = false;
    _onChangedHandler = null;

    constructor(caller) {
        super();
        makeObservable(this, {
            _name: observable,
            _email: observable,
            _isSubmitting: observable,

            name: computed,
            email: computed,
            valid: computed,
            isSubmitting: computed,

            setName: action,
            setEmail: action,
            save: action,
            removeRecord: action,
            setOnChangedHandler: action,
            _setSubmitting: action,

            show: override,
        });
        this._callerCubit = caller;
    }

    get name() { return this._name; }
    get email() { return this._email; }
    get valid() { return this._email && validateEmail(this._email); }
    get isSubmitting() { return this._isSubmitting; }
    get _clientUid() { return useProofXStore.getState().proofX.environment.clientUid; }
    get _isDemoMode() { return useProofXStore.getState().proofX.environment.flags.demoMode; }
    get store() { return useProofXStore.getState(); }

    setOnChangedHandler(handler) {
        this._onChangedHandler = handler;
    }

    _setSubmitting(value) {
        this._isSubmitting = value;
    }

    show(record) {
        this._email = record?.email ?? '';
        this._name = record?.name ?? '';
        super.show();
    }

    setName(name) {
        this._name = name;
    }

    setEmail(email) {
        this._email = email;
    }

    async save() {
        if (!this.valid) return;
        if (!this._isDemoMode) {
            this._setSubmitting(true);
            await api.addOrUpdateAddressBookEntry(this._clientUid, this.email, this.name);
            this._onChangedHandler && this._onChangedHandler();
            this._setSubmitting(false);
        } else {
            this.store.proofX.showAlert(this.store.strings.featureDisabledInDemoMode, 'info', 10000);
        }
        this.hide();
    }

    async removeRecord() {
        if (!this.valid) return;
        if (!this._isDemoMode) {
            this._setSubmitting(true);

            await api.deleteAddressBookEntry(this._clientUid, this.email);
            this._onChangedHandler && this._onChangedHandler();
            this._setSubmitting(false);
        } else {
            this.store.proofX.showAlert(this.store.strings.featureDisabledInDemoMode, 'info', 10000);
        }
        this.hide();
    }
}
