import { action, computed, makeObservable, observable, override } from 'mobx';
import ModalCubit from './ModalCubit';
import EmailPickerCubit from '../uicontrols/EmailPicker/EmailPickerCubit';
import { useProofXStore } from '../Store/ProofXStore';
import DropdownMenuCubit from '../uicontrols/DropdownMenuCubit';
import { api } from '../Api/ProofXApi';
import sendGTMEvent from '../GTM';

export default class ShareModalCubit extends ModalCubit {
    _emailPicker = null;
    _languageSelector = null;
    _language = null;
    _advancedSectionExpanded = false;
    _allowAssetDownload = false;
    _simpleMode = false;
    _allowSubscriptions = false;
    _subject = '';
    _password = '';
    _dueDate = null;
    _comment = '';
    _checklist = null;
    _checklistSelection = null;
    _selectiveApproval = false;

    constructor() {
        super();
        makeObservable(this, {
            _emailPicker: observable.ref,
            _languageSelector: observable.ref,
            _language: observable,
            _advancedSectionExpanded: observable,
            _subject: observable,
            _password: observable,
            _dueDate: observable,
            _checklist: observable,
            _comment: observable,
            _allowAssetDownload: observable,
            _simpleMode: observable,
            _allowSubscriptions: observable,
            _checklistSelection: observable,
            _selectiveApproval: observable,

            emailPicker: computed,
            languageSelector: computed,
            language: computed,
            advancedSectionExpanded: computed,
            subject: computed,
            password: computed,
            dueDate: computed,
            comment: computed,
            allowAssetDownload: computed,
            simpleMode: computed,
            allowSubscriptions: computed,
            checklistSelection: computed,
            checklistCustomized: computed,
            readyToSend: computed,
            selectiveApproval: computed,

            setLanguage: action,
            toggleAdvancedSection: action,
            setSubject: action,
            setPassword: action,
            setDueDate: action,
            setComment: action,
            setChecklist: action,
            toggleAllowAssetDownload: action,
            toggleSimpleMode: action,
            toggleAllowSubscriptions: action,
            setChecklistSelection: action,
            toggleSelectiveApproval: action,

            show: override,
        });
        this._emailPicker = new EmailPickerCubit();
        this._languageSelector = new DropdownMenuCubit(this);
        this.setChecklist(this.checklists.find(c => c.isDefault)?.name ?? null);
        this._simpleMode = this.store.proofX.environment.flags.simpleMode;
        this._allowAssetDownload = this.store.proofX.environment.flags.downloadEnabledByDefault;
        this._selectiveApproval = this.store.proofX.environment.approvalSettings?.clientIsSelectiveApproval ?? false;
    }

    get store() { return useProofXStore.getState(); }
    get emailPicker() { return this._emailPicker; }
    get languageSelector() { return this._languageSelector; }
    get language() { return this._language; }
    get advancedSectionExpanded() { return this._advancedSectionExpanded; }
    get subject() { return this._subject; }
    get password() { return this._password; }
    get dueDate() { return this._dueDate; }
    get checklist() { return this._checklist; }
    get selectedChecklist() { return this.checklists.find(c => c.name === this.checklist); }
    get checklists() { return this.store.proofX.environment?.approvalSettings?.checklists ?? []; }
    get comment() { return this._comment; }
    get allowAssetDownload() { return this._allowAssetDownload; }
    get simpleMode() { return this._simpleMode; }
    get allowSubscriptions() { return this._allowSubscriptions; }
    get checklistSelection() { return this._checklistSelection; }
    get checklistItems() { return this.selectedChecklist?.options?.split(';'); }
    get checklistCustomized() { return (this._checklistSelection?.length ?? 0) < (this.checklistItems?.length ?? 0); }
    get readyToSend() { return this.emailPicker.emailsValid; }

    get selectiveApproval() { return this._selectiveApproval; }

    show() {
        this._language = this._language ?? this.store.strings.getLanguage();
        super.show();
        this._emailPicker.load();
        this._password = '';
        this._dueDate = null;
        this._subject = '';
        this._comment = '';
        this.setChecklist(this._checklist);
    }

    setLanguage(language) {
        this._language = language;
    }

    toggleAdvancedSection(implicitValue) {
        this._advancedSectionExpanded = implicitValue ?? !this._advancedSectionExpanded;
        sendGTMEvent('ShareMenu-Click-Advanced');
    }

    setSubject(subject) {
        this._subject = subject;
    }

    setPassword(password) {
        this._password = password;
    }

    setDueDate(date) {
        this._dueDate = date;
    }

    setComment(text) {
        this._comment = text;
    }

    setChecklist(name) {
        this._checklist = name;
        const items = this.selectedChecklist?.options?.split(';');
        this.setChecklistSelection(items);
    }

    toggleAllowAssetDownload() {
        this._allowAssetDownload = !this._allowAssetDownload;
    }

    toggleAllowSubscriptions() {
        this._allowSubscriptions = !this._allowSubscriptions;
    }

    toggleSelectiveApproval() {
        this._selectiveApproval = !this._selectiveApproval;
    }

    toggleSimpleMode() {
        this._simpleMode = !this._simpleMode;
    }

    setChecklistSelection(items) {
        this._checklistSelection = items;
    }

    dateTime2DateString(datetime) {
        return datetime
            ? [
                datetime.getDate(),
                datetime.getMonth() + 1,
                datetime.getFullYear(),
            ].join('/')
            : null;
    }

    async send() {
        if (!this.readyToSend) return;

        if (this.store.proofX.environment.flags.demoMode) {
            this.store.proofX.showAlert(this.store.strings.featureDisabledInDemoMode, 'info', 10000);
            super.hide();
            return;
        }
        const result = await api.sendReviewEmails(
            this.store.proofX.environment.projectUid,
            this.emailPicker.emails,
            this.dateTime2DateString(this._dueDate) ?? '',
            this._password,
            this._comment,
            this.store.proofX.assets.map(a => a.uid).join(','),
            this._language,
            this._subject,
            this._checklistSelection?.join('||') ?? '',
            this._allowAssetDownload,
            this._simpleMode,
            this._allowSubscriptions,
            this._selectiveApproval,
        );
        sendGTMEvent('ShareMenu-Send-External-task');
        if (result?.reviewTaskUids) {
            this.store.proofX.showAlert(this.store.strings.emailsWereSent, 'info', 10000);
            super.hide();
        }
    }

    async copyShortlink() {
        if (!this.store.proofX.environment.flags.demoMode) {
            const shortlink = await api.getReviewShortlink(this.store.proofX.environment.projectUid, this.store.proofX.assets.map(a => a.uid).join(','));
            navigator.clipboard.writeText(shortlink);
            this.store.proofX.showAlert(this.store.strings.shortlinkCopied, 'info', 10000);
            sendGTMEvent('ShareMenu-Generate-Shortlink');
        } else {
            this.store.proofX.showAlert(this.store.strings.featureDisabledInDemoMode, 'info', 10000);
        }
    }
}
