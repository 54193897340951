import log from '../ProofX/Logger';

class ProofXApi {
    async getConfigValue(key) {
        const url = `/api/GetConfigurationValue/${key}`;
        const result = await this._fetch(url, `Config Value for key '${key}'`);
        return result;
    }

    async checkSessionIntegrity(projectUid, taskUid, sessionToken) {
        const url = `/api/CheckProofXSessionIntegrity/${projectUid}/${taskUid}/${sessionToken}`;
        const result = await this._fetch(url, `Session integrity check (projectUid='${projectUid}', taskUid='${taskUid}', sessionToken='${sessionToken}')`);
        return result;
    }

    async fetchEnvironment(projectUid, taskUid) {
        const url = `/api/GetProofXEnvironment/${projectUid}/${taskUid}`;
        const result = await this._fetch(url, `Environment for (projectUid='${projectUid}', taskUid='${taskUid}')`);
        return result;
    }

    async checkRemoteViewSession(sessionToken) {
        const url = `/api/CheckProofXRemoteViewSession/${sessionToken}`;
        const result = await this._fetch(url, `Remote view session check (sessionToken='${sessionToken}')`);
        return result;
    }

    async getDemoSession() {
        const url = '/api/GetProofXDemoSession';
        const result = await this._fetch(url, 'Demo session check');
        return result;
    }

    async fetchRemoteViewEnvironment() {
        const url = '/api/GetProofXRemoteViewEnvironment';
        const result = await this._fetch(url, 'Remote View Environment');
        return result;
    }

    async fetchDemoEnvironment() {
        const url = '/api/GetProofXDemoEnvironment';
        const result = await this._fetch(url, 'Demo Environment');
        return result;
    }

    async fetchAssets(taskUid, includePdfLayers) {
        const url = `/api/GetProofXAssets/${taskUid}/${includePdfLayers ? 'true' : 'false'}`;
        const result = await this._fetch(url, `Assets for (taskUid='${taskUid}', includePdfLayers='${includePdfLayers}')`);
        return result;
    }

    async fetchRemoteViewAssets() {
        const url = '/api/GetProofXRemoteViewAssets';
        const result = await this._fetch(url, 'Assets for remote view');
        return result;
    }

    async fetchDemoAssets() {
        const url = '/api/GetProofXDemoAssets';
        const result = await this._fetch(url, 'Assets for demo view');
        return result;
    }

    async fetchAnnotations(assetUid, pageNum) {
        const url = `/api/GetAnnotations/${assetUid}/${pageNum}`;
        const result = await this._fetch(url, `Annotations for (assetUid='${assetUid}', pageNum='${pageNum}')`);
        return result;
    }

    async fetchProjectDiscussion(projectUid) {
        const url = `/api/GetProjectDiscussion/${projectUid}`;
        const result = await this._fetch(url, `Project Discussion for (projectUid='${projectUid}'')`);
        return result;
    }

    async fetchSingleAnnotation(commentUid) {
        const url = `/api/GetSingleAnnotation/${commentUid}`;
        const result = await this._fetch(url, `Annotation '${commentUid}'`);
        return result;
    }

    async checkAssetConsistency(assetUid, pageNum) {
        const url = `/api/CheckAssetConsistency/${assetUid}/${pageNum}`;
        const result = await this._fetch(url, `Checking asset consistency for (assetUid='${assetUid}', pageNum='${pageNum}')`);
        return result;
    }

    async restoreServiceFiles(assetUid) {
        const url = `/api/RestoreServiceFiles/${assetUid}`;
        const result = await this._fetch(url, `Restoring service files for (assetUid='${assetUid}')`);
        return result;
    }

    async checkOriginalAssetFileExists(assetUid) {
        const url = `/api/CheckOriginalAssetFileExists/${assetUid}`;
        const result = await this._fetch(url, `Checking original file existence for (assetUid='${assetUid}')`);
        return result;
    }

    async getFileInfo(assetUid) {
        const url = `/api/GetFileInfo/${assetUid}`;
        const result = await this._fetch(url, `Getting file info for (assetUid='${assetUid}')`);
        return result;
    }

    async getAssetPageFormatBasedSize(assetUid, pageNum) {
        const url = `/api/GetAssetPageImageSize/${assetUid}/${pageNum}`;
        const result = await this._fetch(url, `Getting page size for (assetUid='${assetUid}', pageNum='${pageNum}')`);
        return result;
    }

    async createAnnotation(assetUid, pageNumber, text, json, isProjectDiscussion, tempUid) {
        const url = '/api/CreateAnnotation';
        const data = { assetUid, pageNumber, text, json, isProjectDiscussion, tempUid };
        const result = await this._post(url, data, `Creating annotation (text='${text}')`);
        return result;
    }

    async postNewProjectComment(projectUid, text) {
        const url = '/api/CreateProjectComment';
        const data = { projectUid, text };
        const result = await this._post(url, data, `Creating project comment (text='${text}')`);
        return result;
    }

    async sendReply(parentCommentUid, text) {
        const url = '/api/CreateCommentReply';
        const data = { parentCommentUid, text };
        const result = await this._post(url, data, `Sending reply (parentCommentUid='${parentCommentUid}', text='${text}')`);
        return result;
    }

    async saveAnnotation(uid, text, json, skipWritingEvent) {
        const url = '/api/SaveAnnotation';
        const data = { uid, text, json, skipWritingEvent };
        const result = await this._post(url, data, `Saving annotation (uid='${uid}')`);
        return result;
    }

    async toggleReaction(uid, emoji) {
        const url = '/api/ToggleReaction';
        const data = { uid, emoji };
        const result = await this._post(url, data, `Toggling reaction (uid='${uid}', emoji='${emoji}')`);
        return result;
    }

    async toggleCommentComplete(uid, isComplete) {
        const url = '/api/ToggleCommentComplete';
        const data = { uid, isComplete };
        const result = await this._post(url, data, `Setting comment (uid='${uid}') ${isComplete ? 'complete' : 'incomplete'}`);
        return result;
    }

    async toggleCommentInternal(uid, isInternal) {
        const url = '/api/ToggleCommentInternal';
        const data = { uid, isInternal };
        const result = await this._post(url, data, `Setting comment (uid='${uid}') ${isInternal ? 'externally hidden' : 'externally visible'}`);
        return result;
    }

    async deleteAnnotation(uid) {
        const url = '/api/DeleteAnnotation';
        const data = { uid };
        const result = await this._post(url, data, `Deleting annotation (uid='${uid}')`);
        return result;
    }

    uploadCommentAttachment(commentUid, file, setProgress) {
        return new Promise((resolve) => {
            const req = new XMLHttpRequest();
            req.onreadystatechange = function () {
                if (req.readyState === 4) {
                    try {
                        const response = JSON.parse(req.response);
                        if (!response.success) {
                            throw new Error(response);
                        }
                    } catch (error) {
                        throw new Error(req.response);
                    }
                }
            };

            req.upload.addEventListener('progress', event => {
                if (event.lengthComputable) {
                    setProgress((event.loaded / event.total) * 100);
                }
            });

            req.upload.addEventListener('load', event => {
                setProgress(100);
                window.setTimeout(() => resolve(), 500);
            });

            req.upload.addEventListener('error', event => {
                setProgress(null);
                try {
                    const response = JSON.parse(req.response);
                    if (!response.success) {
                        throw new Error(response);
                    }
                } catch (error) {
                    throw new Error(req.response);
                }
            });

            const apiUrl = '/Api/UploadCommentAttachment';

            const formData = new FormData();
            formData.append('commentUid', commentUid);
            formData.append('file', file, file.name);

            req.open('POST', apiUrl);
            req.send(formData);
        });
    }

    async deleteCommentAttachment(commentUid) {
        const url = '/api/DeleteAttachment';
        const data = { commentUid };
        const result = await this._post(url, data, `Deleting attachment for comment (uid='${commentUid}')`);
        return result;
    }

    async checkCompareResultExists(asset1Uid, pageNum1, asset2Uid, pageNum2, compareMode) {
        const url = `/api/CheckCompareResultExists/${asset1Uid}/${pageNum1}/${asset2Uid}/${pageNum2}/${compareMode}`;
        const result = await this._fetch(url, `Chechking compare result exisis (asset1Uid='${asset1Uid}', pageNum1='${pageNum1}', asset2Uid='${asset2Uid}', pageNum2='${pageNum2}', compareMode=${compareMode}')`);
        return result;
    }

    async generateCompareImage(asset1Uid, pageNum1, asset2Uid, pageNum2, compareMode) {
        const url = `/api/GenerateCompareImage/${asset1Uid}/${pageNum1}/${asset2Uid}/${pageNum2}/${compareMode}`;
        const result = await this._fetch(url, `Generating compare image (asset1Uid='${asset1Uid}', pageNum1='${pageNum1}', asset2Uid='${asset2Uid}', pageNum2='${pageNum2}', compareMode=${compareMode}')`);
        return result;
    }

    async changeApprovalStatus(taskUid, newStatus, approvedAssetUidsCSV, rejectedAssetUidsCSV, checklistItems) {
        const url = '/api/ChangeTaskApprovalStatus';
        const data = { taskUid, newStatus, approvedAssetUidsCSV, rejectedAssetUidsCSV, checklistItems };
        const result = await this._post(url, data, 'Changing approval status');
        return result;
    }

    async generatePdfLayers(assetUid) {
        const url = `/api/generatePdfLayers/${assetUid}`;
        const result = await this._fetch(url, `Generating PDF Layers  for (assetUid='${assetUid}')`);
        return result;
    }

    async sendReviewEmails(projectUid, emails, dueDateStr, password, comment, assetUids, locale, subject, checklistOptions, canDownloadAssets, simpleMode, allowSubscriptions, selectiveApproval) {
        const url = '/api/SendReviewEmail';
        const data = {
            projectUid,
            emails,
            dueDateStr,
            password,
            comment,
            assetUids,
            locale,
            subject,
            checklistOptions,
            canDownloadAssets,
            simpleMode,
            allowSubscriptions,
            selectiveApproval,
        };
        const result = await this._post(url, data, 'Sending Review Emails');
        return result;
    }

    async getReviewShortlink(projectUid, assetUids) {
        const url = '/api/GetReviewShortlink';
        const data = {
            projectUid,
            assetUids,
        };
        const result = await this._post(url, data, `Shortlink for (projectUid='${projectUid}', assetUids='${assetUids}')`);
        return result;
    }

    async saveExternalUserName(taskUid, name) {
        const url = '/api/SaveExternalUserName';
        const data = { taskUid, name };
        const result = await this._post(url, data, 'Saving External user name');
        return result;
    }

    async hasPixelInfoGenerated(assetUid, pageNum) {
        const url = `/api/HasPixelInfoGenerated/${assetUid}/${pageNum}`;
        const result = await this._fetch(url, `Presence of generated pixel info for (assetUid='${assetUid}', pageNum='${pageNum}')`);
        return result;
    }

    async getPixelInfo(assetUid, pageNum, point) {
        const url = `/api/GetPixelInfo/${assetUid}/${pageNum}/${point.x}/${point.y}`;
        const result = await this._fetch(url, `Pixel info for (assetUid='${assetUid}', pageNum='${pageNum}', x='${point.x}', y='${point.y}')`);
        return result;
    }

    async scanBarcode(imgUrl) {
        const url = '/api/ScanBarcode';
        const data = { imgUrl };
        const result = await this._post(url, data, 'Scanning Barcode');
        return result;
    }

    async getSpellcheckerLanguages() {
        const url = '/api/GetSpellcheckerLanguages';
        const result = await this._fetch(url, 'Spellchecker Languages');
        return result;
    }

    async spellcheckerRecognizeText(assetUid, pageNum) {
        const url = `/api/SpellcheckerRecognizeText/${assetUid}/${pageNum}`;
        const result = await this._fetch(url, 'Spellchecker Recognize Text');
        return result;
    }

    async spellcheckerGetWrongWords(assetUid, pageNum, langCode) {
        const url = `/api/GetSpellcheckerWrongWords/${assetUid}/${pageNum}/${langCode}`;
        const result = await this._fetch(url, 'Spellchecker Wrong Words');
        return result;
    }

    async markSpellcheckerWord(assetUid, word, isCorrect, langCode) {
        const url = '/api/MarkSpellcheckerWord';
        const data = { assetUid, word, isCorrect, langCode };
        const result = await this._post(url, data, `Mark Spellchecker Word ${word} as ${isCorrect ? 'correct' : 'incorrect'}`);
        return result;
    }

    async getChatGPTResponse(chatUid, question) {
        const url = '/api/GetChatGPTResponse';
        const data = { chatUid, question };
        const result = await this._post(url, data, 'Getting ChatGPT Response');
        return JSON.parse(result);
    }

    async checkUserHasCommentsInAnyAsset(assetUids) {
        const url = '/api/CheckUserHasCommentsInAnyAsset';
        const assetUidsCSV = assetUids.join(',');
        const data = { assetUidsCSV };
        const result = await this._post(url, data, `Checking if user made comments in assets: [${assetUidsCSV}]`);
        return result.result;
    }

    async checkGTMEnabled() {
        const url = '/api/CheckGTMEnabled';
        const result = await this._fetch(url, 'Check if GTM is enabled');
        return result;
    }

    async logGTMEvent(eventString) {
        const url = '/api/LogGTMEvent';
        const data = { eventString };
        await this._post(url, data, `Sending GTM event: ${eventString}`);
    }

    downloadAsset(assetUid) {
        if (!assetUid) return;
        window.location = `/api/DownloadAsset/${assetUid}`;
    }

    async getAddressBook(clientUid) {
        const url = `/api/GetAddressBook/${clientUid}`;
        const result = await this._fetch(url, 'Address book');
        return { contacts: result.contacts ?? [], groups: result.groups ?? [] };
    }

    async addOrUpdateAddressBookEntry(clientUid, email, name) {
        const url = '/api/AddOrUpdateAddressBookEntry';
        const data = { clientUid, email, name };
        const result = await this._post(url, data, `Updating address book record: [${email}, ${name}]`);
        return result;
    }

    async deleteAddressBookEntry(clientUid, email) {
        const url = '/api/DeleteAddressBookEntry';
        const data = { clientUid, email };
        const result = await this._post(url, data, `Deleting address book record: [${email}]`);
        return result;
    }

    async fetchAssetSubscription(assetUid) {
        const url = `/api/GetAssetSubscription/${assetUid}`;
        const result = await this._fetch(url, `Asset subscription for assetUid='${assetUid}'`);
        return result;
    }

    async updateAssetSubscription(assetUid, commentUpdates, fileVersionsUpdates) {
        const url = '/api/UpdateAssetSubscription';
        const data = { assetUid, commentUpdates, fileVersionsUpdates };
        const result = await this._post(url, data, `Saving asset subscription (uid='${assetUid}')`);
        return result;
    }

    _fetch(url, entityName) {
        return new Promise(function (resolve, reject) {
            fetch(url)
                .then(response => {
                    if (response.ok) {
                        return response.text();
                    } else {
                        reject(new Error(`Error fetching ${entityName}`));
                    }
                })
                .then(text => {
                    let result;
                    try {
                        result = JSON.parse(text);
                    } catch (err) {
                        result = text;
                    }
                    log(`🔽 ${entityName} fetched: `, result);
                    resolve(result);
                });
        });
    }

    _getFormData = (object) => Object.keys(object).reduce((formData, key) => {
        if (object[key] !== undefined) {
            formData.append(key, object[key]);
        }
        return formData;
    }, new FormData());

    _post = (url, data, entityName, headers) => {
        if (window.dontChangeAnything) {
            console.trace('🐛 eliminate me!');
            return;
        }

        const formData = this._getFormData(data);
        return new Promise(function (resolve, reject) {
            fetch(url, {
                method: 'POST',
                body: formData,
                headers: new Headers({
                    ...(headers || {}),
                }),
            })
                .then(response => {
                    if (response.ok) {
                        return response.text();
                    } else {
                        reject(new Error(`Error posting ${entityName}`));
                    }
                })
                .then(text => {
                    let result;
                    try {
                        result = JSON.parse(text);
                    } catch (err) {
                        result = text;
                    }
                    log(`🔼 ${entityName} posted: `, result);
                    resolve(result);
                });
        });
    };
}

export const api = new ProofXApi();
